import React from 'react'
import PropTypes from 'prop-types'
import { getContentfulUrl } from '../../../shared/utilities'

const TeamItem = ({ data }) => {
  const { name, title, organisation, email, photo } = data

  const imgQuery = '?w=170&h=220&fit=thumb'
  const imgUrl = photo ? getContentfulUrl(photo) + imgQuery : ''

  return (
    <div className="team-member">
      {photo ? <img src={imgUrl} alt={name} /> : 'Missing photo'}
      <div className="team-member-desc">
        <h3>{name}</h3>
        <p>{title}</p>
        {organisation && <p>{organisation}</p>}
        {/* <p>{profileDescription}</p> */}
        {email && <a href={`mailto:${email}`}>Email me!</a>}
      </div>
    </div>
  )
}

export default TeamItem

TeamItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
}
