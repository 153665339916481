import React from 'react'
import PropTypes from 'prop-types'

import EventItem from './components/EventItem'
import MemberItem from './components/MemberItem'
import TeamItem from './components/TeamItem'
import BlogItem from './components/BlogItem'
import SubMenuItem from './components/SubMenuItem'

const List = ({ data, itemType, className }) => {
  const components = {
    blog: BlogItem,
    events: EventItem,
    members: MemberItem,
    subMenu: SubMenuItem,
    team: TeamItem
  }

  const ItemType = components[itemType]
  const renderItem = data.map(item => {
    const id = 'id' in item && item.id !== null ? item.id : item.node.id
    if (typeof ItemType !== 'undefined') {
      return <ItemType key={id} data={item} />
    }
    return null
  })

  return <div className={`${className}`}>{renderItem}</div>
}
export default List

List.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  itemType: PropTypes.string.isRequired,
  className: PropTypes.string
}

List.defaultProps = {
  className: ''
}
