import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { getContentfulUrl } from '../../../shared/utilities'

const BlogItem = ({ data }) => {
  const { id, slug, author, authorProfileImage, title, image, createdAt, excerpt } = data.node
  const imgQuery = '?w=400&h=300'
  const img = image ? getContentfulUrl(image) + imgQuery : ''
  const authorImg = authorProfileImage
    ? `${getContentfulUrl(authorProfileImage)}?w=50&h=50&r=25`
    : ''
  return (
    <div
      className="row blog-item"
      style={{ paddingBottom: '20px', marginBottom: '40px', borderBottom: '1px solid #ddd' }}
    >
      <div className="col-md-5">
        <img className="post-hero" src={img} alt={title} style={{ maxWidth: '100%' }} />
      </div>

      <div className="col-md-7">
        <Link to={slug}>
          <h2 style={{ marginBottom: '0px' }}>{title}</h2>
        </Link>
        <p style={{ color: '#555' }}>
          <i className="px-icon icon-clipboard3 classic-blog-icon" /> {createdAt}
        </p>
        <span className="author">{author}</span>
        <p>{excerpt}</p>
        <Link
          to={slug}
          className="btn btn-secondary"
          style={{ padding: '10px', textTransform: 'none' }}
        >
          Continue Reading &nbsp;&rarr;
        </Link>
      </div>

      <div className="clearfix" />
    </div>
  )
}

export default BlogItem

BlogItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
}
