import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { getFormattedDate } from '../../../shared/utilities'

const TYPE_KEY = 'locationType'
const isOnline = event => event[TYPE_KEY].includes('Online')

const EventItem = ({ data }) => {
  const formattedDate = getFormattedDate(data.startDate)
  const {
    id,
    title,
    locationState,
    where,
    promotionLine,
    thumbnailImage,
    charity,
    thumbnailImage2
  } = data

  return (
    <Link to={`/events/${data.slug}/${data.id}`} className="event-item">
      <div
        className="event"
        style={{ marginBottom: '20px', background: '#eee' }}
        data-id={id}
        role="button"
        tabIndex={0}
      >
        <div className="event-item-header">
          <img width="100%" src={thumbnailImage} alt={title} />
          <span className="event-item-header-location">
            {locationState}
            {isOnline(data) && '/Online'}
          </span>
        </div>
        <div className="event-item-body">
          <p className="event-item-body-startDate">{formattedDate}</p>
          <h2 className="event-item-body-title">{title}</h2>
          <p className="event-item-body-where">{where}</p>
          <p className="event-item-body-promotionLine">{promotionLine}</p>
        </div>
        <div className="event-item-footer">
          <p className="event-item-footer-charity-name">{charity.name}</p>
        </div>
      </div>
    </Link>
  )
}

export default EventItem

EventItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
}
