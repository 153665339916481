import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { getContentfulUrl } from '../../../shared/utilities'

const SubMenuItem = ({ data }) => {
  const { title, body, slug, image, imageHeight, category, type } = data

  const imgQuery = imageHeight ? `?fit=thumb&w=240&h=${imageHeight}` : '?fit=thumb&w=240'
  const imageUrl = image ? getContentfulUrl(image) + imgQuery : ''
  const headerStyles = { minHeight: imageHeight || 150 }

  return (
    <Link to={slug} className="event-item">
      <div
        className="event"
        style={{ marginBottom: '20px', background: '#eee' }}
        data-id={data.id}
        role="button"
        tabIndex={0}
      >
        <div className="event-item-header" style={headerStyles}>
          {image && <img width="100%" src={imageUrl} alt={title} />}
          {category && <span className="event-item-header-location">{category}</span>}
        </div>
        <div className="event-item-body">
          <h2 className="event-item-body-title" style={{ textAlign: 'center' }}>
            {title}
          </h2>
          {body && <p className="event-item-body-promotionLine">{body}</p>}
        </div>
        {type && (
          <div className="event-item-footer">
            <p className="event-item-footer-charity-name">{type}</p>
          </div>
        )}
      </div>
    </Link>
  )
}

export default SubMenuItem

SubMenuItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
}
