import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import List from '../../components/List'
import LinksMenu from '../../components/LinksMenu'
import LayoutListView from '../../components/LayoutListView'
import { pluck } from '../../shared/utilities'

const AccommodationPage = ({ location }) => {
  const configID = 'ZjEv4aRiI5M2jK5QasHcF'

  const [filteredData, setFilteredData] = useState([])
  const [selectedValue, setSelectedValue] = useState('All States')

  const queryData = useStaticQuery(graphql`
    {
      allContentfulAccommodation(sort: { fields: title, order: ASC }) {
        edges {
          node {
            id
            title
            slug
            description {
              json
            }
            region
            longitude
            latitude
            profileImage {
              file {
                url
              }
            }
            createdAt(formatString: "MMMM Do YYYY")
          }
        }
      }
    }
  `)

  const TYPE = 'Accommodation'
  const IMAGE_HEIGHT = 200
  const data = queryData.allContentfulAccommodation.edges.map(obj => {
    const slug = obj.node.slug[0] !== '/' ? `/${obj.node.slug}` : obj.node.slug

    return {
      id: obj.node.id,
      title: obj.node.title,
      body: null,
      image: obj.node.profileImage,
      imageHeight: IMAGE_HEIGHT,
      slug: `/resources/accommodation${slug}`,
      category: obj.node.region,
      type: TYPE
    }
  })

  useEffect(() => {
    setFilteredData(data)
  }, [])

  const STATE_KEY = 'category'
  const states = pluck(data, STATE_KEY)

  function filterData(key, value) {
    const filterEvents = data.filter(item => item[key] === value)
    setFilteredData(filterEvents)
    setSelectedValue(value)
  }

  function resetFilter() {
    setFilteredData(data)
    setSelectedValue('All States')
  }

  function handleClick(e) {
    const value = e.currentTarget.dataset.id
    filterData(STATE_KEY, value)
  }

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <Link to="/resources" className="back-link">
        &larr; Resources
      </Link>
      <LinksMenu
        title="All States"
        items={states}
        type="state"
        handleClick={handleClick}
        resetFilter={resetFilter}
        selectedValue={selectedValue}
        style={{ margin: '0' }}
      />
      <List data={filteredData} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

export default AccommodationPage
