import React from 'react'
import PropTypes from 'prop-types'

const MemberItem = ({ data }) => {
  return (
    <a
      href={data.charityURL}
      target="_blank"
      rel="noopener noreferrer"
      alt={data.name}
      className="member-item"
    >
      <div style={{ minHeight: 130 }}>
        <img width="130px" src={data.charityLogo} alt={data.name} />
      </div>
      <div className="member-item-desc">
        <h3>{data.name}</h3>

        <p className="member-item-desc-promotionLine">{data.promotionLine}</p>
        {/* <a href={data.charityURL} alt={data.name}>
          website
        </a> */}
        <p className="member-item-desc-membership">{data.membership}</p>
      </div>
    </a>
  )
}

export default MemberItem

MemberItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired
}
